<button
  [id]="id"
  (click)="handleClick()"
  class="w-full rounded flex items-center text-white dark:text-black justify-center font-standard font-medium"
  [disabled]="disabled"
  [ngClass]="{
    'bg-red-500 hover:bg-red-600 dark:hover:bg-red-400 dark:text-white': danger,
    'hover:bg-gray-800 dark:hover:bg-gray-100': !danger,
    'cursor-not-allowed': disabled,
    'cursor-pointer': !disabled,
    'bg-black dark:bg-white/[.87]': !danger && !loading,
    'bg-gray-800 dark:bg-gray-200': !danger && loading,
    'px-2 py-2 text-xs': small,
    'px-6 py-3 text-sm': !small
  }"
>
  <app-loading *ngIf="loading" class="w-5 h-5 mr-3.5"></app-loading>
  <ng-content *ngIf="loading" select="[loading-text]"></ng-content>

  <div
    class="h-5 w-5 mr-4"
    [ngClass]="{ 'mr-2': small, hidden: !hasIcon || loading }"
    #iconSlot
  >
    <ng-content select="[icon]"></ng-content>
  </div>
  <ng-content *ngIf="!loading" select="[text]"></ng-content>
</button>
