import { Injectable } from "@angular/core";
import { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { environment } from "@environments/environment";
import { Router } from "@angular/router";
import { Account, AccountRoles, AccountStatus } from "@shared/models";
import { HttpService } from "./http.service";
import { map } from "rxjs/operators";

Auth.configure({
  region: "us-east-1",
  userPoolId: environment.cognitoUserPoolId,
  userPoolWebClientId: environment.cognitoUserPoolWebClientId,
  oauth: {
    domain: environment.cognitoDomain,
    scope: ["phone", "email", "profile", "openid"],
    redirectSignIn: `${environment.domain}/app`,
    redirectSignOut: `${environment.domain}/`,
    responseType: "code",
  },
});

type user = { accounts; email: string; shared?: boolean };
@Injectable({
  providedIn: "root",
})
export class AuthService {
  token: string;
  user: user;
  currentAccount: Partial<Account>;
  shared: boolean;
  customState: any = {
    url: null,
  };
  Auth = Auth;

  constructor(private router: Router, private httpService: HttpService) {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log({ event, data });
      switch (event) {
        case "signIn":
          break;
        case "signOut":
          // this.router.navigate(["/"]);
          // console.log(event, data);
          break;
        case "customOAuthState":
          data = data && JSON.parse(data);
          if (data.url) {
            this.customState.url = data.url;
          }
      }
    });
  }

  async getToken() {
    const token = this.token;
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    const expired = Math.floor(new Date().getTime() / 1000) >= expiry;
    console.log({ expired });
    if (expired) {
      await this.refreshSession();
    }
    return this.token;
  }

  async setToken(token: string) {
    const parsedToken = this.parseToken(token);
    this.shared = parsedToken.shared;

    this.token = token; //order matters here
    parsedToken.accounts = await this.getAccounts();
    this.user = parsedToken;
    console.log({ user: this.user });
    localStorage.setItem("RP_TK", token);
  }

  async getLocalToken() {
    const token = localStorage.getItem("RP_TK");
    await this.setToken(token); //set auth service properties
    return token;
  }

  async refreshSharedToken() {
    const token = await this.httpService
      .get("/user/shared-token/new")
      .pipe(map((res) => res.token))
      .toPromise();
    await this.setToken(token);
    return token;
  }

  async refreshSession() {
    if (this.shared) {
      const token = await this.refreshSharedToken();
      return token;
    } else {
      try {
        const session = await Auth.currentSession();
        const refresh_token = await session.getRefreshToken();
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const refreshed = await new Promise(function (resolve, reject) {
          cognitoUser.refreshSession(refresh_token, resolve);
        });
        await this.getAmplifyIdToken();
        return refreshed;
      } catch (e) {
        console.log("Cognito refreshSession error", e);
      }
    }
  }

  async getAmplifyIdToken() {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    console.log({ token });
    if (token) {
      await this.setToken(token);
    }
    return token;
  }

  async getUserAndCurrentAccount() {
    const user = await this.getUserInfo();
    if (user) {
      user.accounts = await this.getAccounts();
      this.user = user;
      const account = this.getCurrentAccount();
      return { user, account };
    } else {
      return null;
    }
  }

  getCurrentAccount(): Partial<Account> {
    if (this.user && this.user.accounts && this.user.accounts.length === 1) {
      this.currentAccount = { ...this.user.accounts[0] };
      return { ...this.currentAccount };
    } else {
      const storedCurrentAccountId = localStorage.getItem(
        "RP_CURRENT_ACCOUNT_ID"
      );

      if (storedCurrentAccountId && this.user && this.user.accounts) {
        const account = this.user.accounts.find((account) => {
          return account._id === storedCurrentAccountId;
        });
        if (account) {
          this.currentAccount = account;
          return { ...this.currentAccount };
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  setCurrentAccount(account: Partial<Account>) {
    this.currentAccount = { ...account };
    localStorage.setItem("RP_CURRENT_ACCOUNT_ID", this.currentAccount._id);
  }

  async getUserInfo() {
    let token;
    try {
      token = await this.getAmplifyIdToken();
    } catch (e) {
      const localToken = this.token || (await this.getLocalToken());
      if (localToken) {
        //token from link params
        return this.parseToken(localToken);
      }
    }
    const user = this.parseToken(token);
    user.accounts = await this.getAccounts();
    return user;
  }

  async continueWithGoogle(state?: object) {
    const user = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: JSON.stringify(state || {}),
    });
  }

  async signOut(reroute: boolean = false) {
    localStorage.removeItem("RP_CURRENT_ACCOUNT_ID");
    localStorage.removeItem("RP_TK");

    if (reroute) {
      await Auth.signOut();
      this.router.navigate(["/"]);
    } else {
      localStorage.setItem("amplify-signin-with-hostedUI", "false"); //hack to prevent redirect on signout
      await Auth.signOut();
    }
  }

  getRole() {
    return this.currentAccount?.role;
  }

  private getAccounts() {
    return this.httpService
      .get("/account/list")
      .pipe(map((res) => res.accounts))
      .toPromise();
  }

  private parseToken(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const user: user = JSON.parse(jsonPayload);
    return user;
  }
}
