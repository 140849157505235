import Package from "../../package.json";

export const environment = {
  version: Package.version + "-release",
  production: false,
  baseUrl: "https://api.qa.prohura.com",
  domain: "https://qa.prohura.com",
  cognitoUserPoolId: "us-east-1_ibIFeTeUh",
  cognitoUserPoolWebClientId: "5q72uaupl05uf4l4o3emuln0bo",
  cognitoDomain: "auth.qa.prohura.com",
  stripePk:
    "pk_test_51I84K4DSFiO79ZcXKqHpLnFpwcrhoepknVaKG6c8pjbkd177JSPQgROXNEppc5yvKe0bZYsWTD1jIJH6w6TCC08z00fV7QTssf",
  googleTrackingId: "G-DH54GP0CL3",
};
