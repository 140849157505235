import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { VersionComponent } from "./components/version/version.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTabsModule } from "@angular/material/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BillingComponent } from "./components/billing/billing.component";
import { BillingPlanComponent } from "./components/billing/billing-plan/billing-plan.component";
import { PaymentMethodComponent } from "./components/billing/payment-method/payment-method.component";
import { PlusComponent } from "./icons/plus/plus.component";
import { XComponent } from "./icons/x/x.component";
import { CheckComponent } from "./icons/check/check.component";
import { EditComponent } from "./icons/edit/edit.component";
import { VisaComponent } from "./icons/visa/visa.component";
import { ChevronDownComponent } from "./icons/chevron-down/chevron-down.component";
import { BillingPlanLoadingComponent } from "./loaders/billing-plan-loading/billing-plan-loading.component";
import { PaymentMethodLoadingComponent } from "./loaders/payment-method-loading/payment-method-loading.component";
import { ArrowLeftComponent } from "./icons/arrow-left/arrow-left.component";
import { AttachmentComponent } from "./icons/attachment/attachment.component";
import { ArrowRightCircleComponent } from "./icons/arrow-right-circle/arrow-right-circle.component";
import { PaperAirplaneComponent } from "./icons/paper-airplane/paper-airplane.component";
import { CogComponent } from "./icons/cog/cog.component";
import { BeakerComponent } from "./icons/beaker/beaker.component";
import { UsersComponent } from "./icons/users/users.component";
import { MapComponent } from "./icons/map/map.component";
import { BellComponent } from "./icons/bell/bell.component";
import { OrganizationComponent } from "./icons/organization/organization.component";
import { GroupComponent } from "./icons/group/group.component";
import { MailComponent } from "./icons/mail/mail.component";
import { CheckCircleComponent } from "./icons/check-circle/check-circle.component";
import { ImageComponent } from "./icons/image/image.component";
import { LinkComponent } from "./icons/link/link.component";
import { CodeComponent } from "./icons/code/code.component";
import { OrderedListComponent } from "./icons/ordered-list/ordered-list.component";
import { UnorderedListComponent } from "./icons/unordered-list/unordered-list.component";
import { DownloadComponent } from "./icons/download/download.component";
import { DocumentTextComponent } from "./icons/document-text/document-text.component";
import { ChatComponent } from "./icons/chat/chat.component";
import { CopyComponent } from "./icons/copy/copy.component";
import { DollarComponent } from "./icons/dollar/dollar.component";
import { PrimaryButtonComponent } from "./buttons/primary-button/primary-button.component";
import { SecondaryButtonComponent } from "./buttons/secondary-button/secondary-button.component";
import { LoadingComponent } from "./loaders/loading/loading.component";
import { InputComponent } from "./components/input/input.component";
import { SearchComponent } from "./icons/search/search.component";
import { ArrowRightComponent } from "./icons/arrow-right/arrow-right.component";
import { ChevronRightComponent } from "./icons/chevron-right/chevron-right.component";
import { ChevronLeftComponent } from "./icons/chevron-left/chevron-left.component";
import { IconButtonComponent } from "./buttons/icon-button/icon-button.component";
import { VerticalDotsComponent } from "./icons/vertical-dots/vertical-dots.component";
import { ShareComponent } from "./icons/share/share.component";
import { ArchiveComponent } from "./icons/archive/archive.component";
import { StopComponent } from "./icons/stop/stop.component";
import { BookmarkComponent } from "./icons/bookmark/bookmark.component";
import { LogoutComponent } from "./icons/logout/logout.component";
import { DeleteComponent } from "./icons/delete/delete.component";
import { TextareaComponent } from "./components/textarea/textarea.component";
import { TrendingUpComponent } from "./icons/trending-up/trending-up.component";
import { PresentationLineComponent } from "./icons/presentation-line/presentation-line.component";
import { ChevronDoubleRightComponent } from "./icons/chevron-double-right/chevron-double-right.component";
import { ChevronDoubleLeftComponent } from "./icons/chevron-double-left/chevron-double-left.component";
import { HeadingComponent } from "./components/heading/heading.component";
import { EyeComponent } from "./icons/eye/eye.component";
import { EyeOffComponent } from "./icons/eye-off/eye-off.component";
import { ClockComponent } from "./icons/clock/clock.component";
import { TerminalComponent } from "./icons/terminal/terminal.component";
import { OfficeBuildingComponent } from "./icons/office-building/office-building.component";
import { GlobeComponent } from "./icons/globe/globe.component";
import { LockComponent } from "./icons/lock/lock.component";
import { StepComponent } from "./icons/step/step.component";
import { PhaseComponent } from "./icons/phase/phase.component";
import { ArrowNarrowRightComponent } from "./icons/arrow-narrow-right/arrow-narrow-right.component";
import { ArrowNarrowLeftComponent } from "./icons/arrow-narrow-left/arrow-narrow-left.component";
import { ArrowDownComponent } from "./icons/arrow-down/arrow-down.component";
import { ArrowUpComponent } from "./icons/arrow-up/arrow-up.component";
import { ListComponent } from "./icons/list/list.component";
import { ArtifactComponent } from "./icons/artifact/artifact.component";
import { TimelineStepStatusComponent } from "../dashboard/shared/timeline-step-status/timeline-step-status.component";
import { ProcessPreviewComponent } from "../dashboard/shared/process-preview/process-preview.component";
import { ToggleSliderComponent } from "./components/toggle-slider/toggle-slider.component";
import { PricingCalculatorComponent } from "../landing/pricing-calculator/pricing-calculator.component";
import { ColorButtonComponent } from "./buttons/color-button/color-button.component";
import { BillingLengthToggleComponent } from "./components/billing/billing-length-toggle/billing-length-toggle.component";
import { RouterModule } from "@angular/router";
import { SwitchComponent } from "./icons/switch/switch.component";
import { PlanLoadingItemComponent } from "./components/billing/plan/plan-loading-item/plan-loading-item.component";
import { ProhuraComponent } from "./icons/prohura/prohura.component";
import { AmericanexpressComponent } from "./icons/americanexpress/americanexpress.component";
import { DiscovercardComponent } from "./icons/discovercard/discovercard.component";
import { MastercardComponent } from "./icons/mastercard/mastercard.component";
import { VideoComponent } from "./icons/video/video.component";
import { FolderComponent } from "./icons/folder/folder.component";
import { DashboardSidebarComponent } from "../dashboard/dashboard-sidebar/dashboard-sidebar.component";
import { NavItemComponent } from "../dashboard/shared/nav-item/nav-item.component";

@NgModule({
  declarations: [
    VersionComponent,
    BillingComponent,
    BillingPlanComponent,
    PaymentMethodComponent,
    PlusComponent,
    XComponent,
    CheckComponent,
    EditComponent,
    VisaComponent,
    ChevronDownComponent,
    BillingPlanLoadingComponent,
    PaymentMethodLoadingComponent,
    ArrowLeftComponent,
    AttachmentComponent,
    ArrowRightCircleComponent,
    PaperAirplaneComponent,
    CogComponent,
    BeakerComponent,
    UsersComponent,
    MapComponent,
    BellComponent,
    OrganizationComponent,
    GroupComponent,
    MailComponent,
    CheckCircleComponent,
    ImageComponent,
    LinkComponent,
    CodeComponent,
    OrderedListComponent,
    UnorderedListComponent,
    DownloadComponent,
    DocumentTextComponent,
    ChatComponent,
    CopyComponent,
    DollarComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    LoadingComponent,
    InputComponent,
    SearchComponent,
    ArrowRightComponent,
    ChevronRightComponent,
    ChevronLeftComponent,
    IconButtonComponent,
    VerticalDotsComponent,
    ShareComponent,
    ArchiveComponent,
    StopComponent,
    BookmarkComponent,
    LogoutComponent,
    DeleteComponent,
    TextareaComponent,
    TrendingUpComponent,
    PresentationLineComponent,
    ChevronDoubleRightComponent,
    ChevronDoubleLeftComponent,
    HeadingComponent,
    EyeComponent,
    EyeOffComponent,
    ClockComponent,
    TerminalComponent,
    OfficeBuildingComponent,
    GlobeComponent,
    LockComponent,
    StepComponent,
    PhaseComponent,
    ArrowNarrowRightComponent,
    ArrowNarrowLeftComponent,
    ArrowDownComponent,
    ArrowUpComponent,
    ListComponent,
    ArtifactComponent,
    TimelineStepStatusComponent,
    ProcessPreviewComponent,
    ToggleSliderComponent,
    PricingCalculatorComponent,
    ColorButtonComponent,
    BillingLengthToggleComponent,
    SwitchComponent,
    PlanLoadingItemComponent,
    ProhuraComponent,
    AmericanexpressComponent,
    DiscovercardComponent,
    MastercardComponent,
    VideoComponent,
    FolderComponent,
    // DashboardSidebarComponent,
    // NavItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    RouterModule,
  ],
  exports: [
    VersionComponent,
    BillingComponent,
    BillingPlanComponent,
    PaymentMethodComponent,
    PlusComponent,
    XComponent,
    CheckComponent,
    EditComponent,
    VisaComponent,
    ChevronDownComponent,
    BillingPlanLoadingComponent,
    PaymentMethodLoadingComponent,
    ArrowLeftComponent,
    AttachmentComponent,
    ArrowRightCircleComponent,
    PaperAirplaneComponent,
    CogComponent,
    BeakerComponent,
    UsersComponent,
    MapComponent,
    BellComponent,
    OrganizationComponent,
    GroupComponent,
    MailComponent,
    CheckCircleComponent,
    ImageComponent,
    LinkComponent,
    CodeComponent,
    OrderedListComponent,
    UnorderedListComponent,
    DownloadComponent,
    DocumentTextComponent,
    ChatComponent,
    CopyComponent,
    DollarComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    LoadingComponent,
    InputComponent,
    SearchComponent,
    ArrowRightComponent,
    ChevronLeftComponent,
    ChevronRightComponent,
    VerticalDotsComponent,
    ShareComponent,
    IconButtonComponent,
    ArchiveComponent,
    StopComponent,
    BookmarkComponent,
    LogoutComponent,
    DeleteComponent,
    TextareaComponent,
    TrendingUpComponent,
    PresentationLineComponent,
    ChevronDoubleRightComponent,
    ChevronDoubleLeftComponent,
    HeadingComponent,
    EyeComponent,
    EyeOffComponent,
    ClockComponent,
    TerminalComponent,
    OfficeBuildingComponent,
    GlobeComponent,
    LockComponent,
    StepComponent,
    PhaseComponent,
    ArrowNarrowLeftComponent,
    ArrowNarrowRightComponent,
    ArrowDownComponent,
    ArrowUpComponent,
    ListComponent,
    ArtifactComponent,
    TimelineStepStatusComponent,
    ProcessPreviewComponent,
    ToggleSliderComponent,
    PricingCalculatorComponent,
    ColorButtonComponent,
    BillingLengthToggleComponent,
    SwitchComponent,
    PlanLoadingItemComponent,
    ProhuraComponent,
    MastercardComponent,
    AmericanexpressComponent,
    DiscovercardComponent,
    VideoComponent,
    FolderComponent,
    // DashboardSidebarComponent,
    // NavItemComponent,
  ],
})
export class SharedModule {}
