import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@services/auth.service";
import {
  Phase,
  PhaseTemplate,
  ProcessTemplate,
  Project,
  Service,
} from "@shared/models";
import { AccountRoles } from "src/entity_types";

@Component({
  selector: "process-preview",
  templateUrl: "./process-preview.component.html",
  styleUrls: ["./process-preview.component.scss"],
})
export class ProcessPreviewComponent implements OnInit {
  @Input("service") set service(service: Service) {
    this._service = service;
    if (service.process_template) {
      this.phase_templates = service.process_template.phase_templates;
    }
    console.log({ service });
  }
  @Input() set process_template(process_template: ProcessTemplate) {
    console.log({ process_template });
    if (process_template) {
      this.sortPhaseTemplates(process_template.phase_templates);
    }
  }

  @Input() set project(project: Partial<Project>) {
    if (project) {
      this._project = project;
    }
  }
  @Input() mocked = false;

  phase_templates: PhaseTemplate[] = [];
  _service: Service;
  _project: Partial<Project>;
  isOwner: boolean;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    const role = this.authService.getRole();
    this.isOwner = AccountRoles.ADMIN_OWNER === role;
  }

  editServiceClick() {
    if (!this._service) return;

    this.router.navigateByUrl(`app/admin/service/${this._service._id}/edit`, {
      state: {
        service: this._service,
        returnUrl: this.router.url,
        project: this._project,
      },
    });
  }

  copyServiceClick() {
    if (!this._service) return;

    this.router.navigateByUrl(`app/admin/service/${this._service._id}/copy`, {
      state: {
        service: this._service,
        returnUrl: this.router.url,
        project: this._project,
      },
    });
  }

  private sortPhaseTemplates(phase_templates: PhaseTemplate[]) {
    console.log(phase_templates);
    this.phase_templates = phase_templates.sort((a, b) => a.index - b.index);
  }
}
