<div
  class="font-standard relative h-10 w-80 my-2 border-2 border-gray-300 dark:border-gray-800 rounded bg-gray-300 dark:bg-gray-800 text-black flex items-center justify-between overflow-hidden cursor-pointer"
>
  <p
    class="z-20 w-1/2 h-full flex items-center justify-center text-center text-sm font-semibold"
    [ngClass]="{ 'text-gray-600 dark:text-gray-400': length != 'month' }"
    (click)="handleClick('month')"
  >
    Monthly Billing
  </p>
  <p
    class="z-20 w-1/2 h-full flex items-center justify-center text-center text-sm font-semibold"
    (click)="handleClick('year')"
    [ngClass]="{ 'text-gray-600 dark:text-gray-400': length != 'year' }"
  >
    Yearly Billing
  </p>
  <span
    [@toggle]="length"
    class="absolute z-10 w-1/2 h-full rounded bg-white"
  ></span>
</div>
