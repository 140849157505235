import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from "@angular/core";
import { NotificationService } from "@services/notification.service";
import { Plan, PlanService } from "@services/plan.service";
import { StripeService } from "@services/stripe.service";

@Component({
  selector: "app-billing-plan",
  templateUrl: "./billing-plan.component.html",
  styleUrls: ["./billing-plan.component.scss"],
})
export class BillingPlanComponent implements OnInit {
  @Input() billing: boolean;
  @Input() selectedPlan: Plan;
  @Output() selectedPlanChange = new EventEmitter<any>();

  selected = -1;
  plans: any[] = [];
  promoCode: string = "";
  promoId: string = "";
  percentOff = 0;
  planDuration = "month";
  loading = false;

  showPromoCode: boolean = false;

  constructor(
    private stripeService: StripeService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.getPlans();
  }

  getPlans(percentageOff: number = 0) {
    this.stripeService.getPlansWithPricing(percentageOff).subscribe(
      (plans) => {
        console.log({ plans });
        this.plans = plans;
      },
      (err) => {
        console.log({ err });
        this.notificationService.handleError(
          "Unable to get plans at this time."
        );
      },
      () => {
        this.loading = false;
      }
    );
  }

  applyPromoCode() {
    this.stripeService.fetchPromoCode(this.promoCode).subscribe(
      ({ promo }) => {
        console.log(promo);
        if (promo) {
          this.stripeService.promoId = promo.id;
          this.promoId = promo.id;
          this.notificationService.handleSuccess(
            `Applied Promo Code ${this.promoCode}`
          );
          this.percentOff = promo.coupon.percent_off;
          this.getPlans(this.percentOff);
          console.log({ percentOff: this.percentOff });
        } else {
          this.notificationService.handleError(`Invalid Promo Code`);
        }
      },
      (err) => {
        this.notificationService.handleError(
          `Unable to apply promo code at this time.`
        );
      }
    );
  }

  selectPlan(plan: Plan) {
    this.selectedPlan = plan;
    console.log({ plan });
    this.stripeService.selectPlan(plan);
    this.selectedPlanChange.emit({ ...this.selectedPlan });
  }
}
