export interface Organization {
  _id: string;
  displayName: string;
  name: string;
  _created_at: string;
  _updated_at: string;
  status: string;
  clients: Account[];
  admins: Account[];
}

export interface Account {
  _id: string;
  username: string;
  name: string;
  _created_at: string;
  _updated_at: string;
  account_status: AccountStatus;
  email: string;
  role: AccountRoles;
  organization: Organization;
  admin_organization: Organization;
  customer_id: string;
}

export interface User {
  _id: string;
  email: string;
  _created_at: string;
  _updated_at: string;
  accounts: Account[];
}

export interface Service {
  _id: string;
  _created_at: string;
  _updated_at: string;
  created_by: Account;
  name: string;
  description: string;
  process_template: ProcessTemplate;
  projects: Project[];
  organization: Organization;
}

export interface Project {
  _id: string;
  _created_at: string;
  _updated_at: string;
  name: string;
  description: string;
  status: Status;
  labels: string[];
  client_organization: Partial<Organization>;
  clients: Account[];
  admins: Account[];
  service: Service;
  iterations: Iteration[];
  isPublic: boolean;
}

export interface ProcessTemplate {
  work_business_days: boolean;
  phase_templates: PhaseTemplate[];
  _updated_at?: string;
  _created_at?: string;
  _id?: string;
}

export interface PhaseTemplate {
  name: string;
  description?: string;
  step_templates: StepTemplate[];
  _updated_at?: string;
  _created_at?: string;
  _id?: string;
  index: number;
}

export interface StepTemplate {
  name: string;
  duration_in_days: number;
  offset_in_days: number;
  description?: string;
  _updated_at?: string;
  _created_at?: string;
  _id?: string;
  instruction_content: string;
  index: number;
}
export interface Instruction {
  content: string;
  _updated_at: string;
  _created_at: string;
  _id: string;
}

export interface Iteration {
  _id: string;
  _created_at: string;
  _updated_at: string;
  name: string;
  description?: string;
  status: Status;
  genesis: string;
  phases: Phase[];
  service?: Service;
  genesisDate?: Date;
}

export interface Process {
  _id: string;
  _created_at: string;
  _updated_at: string;
  phases: Phase[];
}

export interface Phase {
  _id: string;
  _created_at: string;
  _updated_at: string;
  start_date: string;
  end_date: string;
  name: string;
  description?: string;
  status: Status;
  iteration?: Iteration;
  steps: Step[];
  index: number;
}

export interface Step {
  _id: string;
  _created_at: string;
  _updated_at: string;
  start_date: string;
  end_date: string;
  name: string;
  artifacts: Artifact[];
  description?: string;
  index: number;
  status: Status;
  instruction?: Instruction;
}

export interface Artifact {
  _id: string;
  name: string;
  _created_at: string;
  _updated_at: string;
  content: string;
  author: Account;
  files: File[];
  visibleAt: Date;
  title: string;
}

export interface File {
  _id: string;
  name: string;
  _created_at: string;
  _updated_at: string;
  content_type: string;
  uploaded: boolean;
  url?: string;
}

export interface AccessRequest {
  _id: string;
  requestor: Account;
  requestee: Account;
  _created_at: string;
  _updated_at: string;
}

export interface Invite {
  _id: string;
  _created_at: string;
  _updated_at: string;
  invitor: Account;
  invitee: Account;
  status: InviteStatus;
  project?: Project;
  send_at: Date;
}

export type AccountRoles = "admin" | "admin_owner" | "client";
export type AccountStatus = "archived" | "incomplete" | "active" | "pending";
export type Status = "Not Started" | "Archived" | "Completed" | "In Progress";
export enum StatusTypes {
  NOT_STARTED = "Not Started",
  ARCHIVED = "Archived",
  COMPLETED = "Completed",
  IN_PROGRESS = "In Progress",
}
export type InviteStatus = "sent" | "scheduled" | "canceled";

export const AdminRoles = ["admin", "admin_owner"];
