import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Observable, throwError, of, from } from "rxjs";
import { Router } from "@angular/router";
import { catchError, mergeMap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.authService.getToken()).pipe(
      mergeMap((token) => {
        let headers = request.headers;

        if (request.url.includes("s3.amazonaws.com/uploaded/")) {
          //ignore
        } else if (token) {
          headers = headers.set("Authorization", `Bearer ${token}`);
          if (this.authService.shared) {
            headers = headers.set("Shared", `${this.authService.shared}`);
          }
        } else {
          this.router.navigate(["/"]);
        }

        if (request.url.includes("/account/list")) {
          console.log({ here: "here" });
          //ignore account
        } else {
          const current_account = this.authService.getCurrentAccount();
          if (current_account && current_account._id) {
            headers = headers.set("account_id", current_account._id);
          } else {
            this.router.navigate(["/accounts"]);
          }
        }

        if (request.method === "POST") {
          headers = headers.set("Content-Type", "application/json");
        }

        request = request.clone({
          headers,
          params: request.params,
          withCredentials: false,
        });

        // redirect on api response
        return next.handle(request).pipe(
          catchError((err) => {
            console.log({ err });
            if (err.status === 403) {
              this.router.navigate(["/app/403"]);
            } else if (err.status === 404) {
              this.router.navigate(["/app/404"]);
            } else if (err.status === 401) {
              this.authService.signOut();
              this.router.navigate(["/signinquick"]); //TODO: navigate to signinquick
            }
            return throwError(err);
          })
        );
      })
    );
  }
}

export const authProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
