<div
  class="w-auto my-4 -mx-4 p-4 sm:p-8 rounded-lg bg-gray-50 dark:bg-darkgray-800"
>
  <div class="w-full flex">
    <div>
      <div class="text-sm text-gray-600">Process Preview</div>
      <div class="font-bold text-lg">
        {{ _service.name }}
      </div>
    </div>

    <ng-container *ngIf="!(mocked || !isOwner)">
      <secondary-button class="ml-auto mr-4" (click)="copyServiceClick()">
        <icon-copy icon></icon-copy>
        <p text class="hidden sm:block">Copy Service</p>
        <p text class="sm:hidden">Copy</p>
      </secondary-button>
      <secondary-button (click)="editServiceClick()">
        <icon-edit icon></icon-edit>
        <p text class="hidden sm:block">Edit Service</p>
        <p text class="sm:hidden">Edit</p>
      </secondary-button>
    </ng-container>
  </div>

  <div class="flex overflow-x-auto">
    <div
      class="px-4 flex flex-col items-center text-gray-300 dark:text-gray-700"
      *ngFor="let phase_template of phase_templates; let i = index"
    >
      <div class="px-4 flex items-end justify-evenly">
        <div
          *ngFor="
            let step_template of phase_template.step_templates;
            let j = index
          "
          class="w-24 -mx-4 flex flex-col items-center"
        >
          <p
            class="w-full h-14 my-2 font-bold rounded text-sm text-gray-600 text-center overflow-hidden flex justify-center items-end font-exo2"
          >
            {{ step_template.name }}
          </p>
          <svg
            style="
              min-height: 24px;
              min-width: 24px;
              box-shadow: inset 8px 0 3px 0 rgba(0, 0, 0, 0.2);
            "
            class="rounded-full"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="50%"
              cy="50%"
              r="12"
              fill="none"
              stroke="currentColor"
              stroke-width="6"
            />
            <circle
              class="text-white dark:text-black"
              cx="50%"
              cy="50%"
              r="8"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            />
          </svg>
          <svg
            [ngClass]="j % 2 === 0 ? 'h-40' : 'h-24'"
            width="5"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <rect width="100%" height="100%" />
          </svg>
        </div>
      </div>
      <div class="w-full relative flex items-center">
        <svg
          class="w-full h-6 mb-8"
          height="100%"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <rect width="100%" height="100%" />
        </svg>
        <svg
          *ngIf="i < phase_templates.length - 1"
          class="absolute top-0 left-full w-8 h-6 mb-8"
          height="100%"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <rect width="100%" height="100%" />
        </svg>
      </div>
      <div class="whitespace-nowrap text-xs text-gray-500 font-semibold">
        PHASE {{ i + 1 }}
      </div>
      <div
        class="text-gray-800 dark:text-gray-300 text-sm font-semibold font-exo2"
        *ngIf="phase_template.name"
      >
        {{ phase_template?.name }}
      </div>
      <svg
        class="h-6 mb-8 flex items-center justify-center"
        height="100%"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <rect width="4px" height="100%" />
        <rect width="100%" height="4px" y="calc(50% - 2px)" />
        <rect width="4px" height="100%" x="calc(100% - 4px)" />
      </svg>
    </div>
  </div>
</div>
