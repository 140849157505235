import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { HttpService } from "./http.service";
import { Plan, PlanService } from "./plan.service";
import { UtilService } from "./util.service";

declare var Stripe: stripe.StripeStatic;

@Injectable({
  providedIn: "root",
})
export class StripeService {
  stripe = Stripe(environment.stripePk);
  cardElement;

  promoId: string;
  priceId: string;
  inputIsValid: boolean = false;

  selectedPlan = new EventEmitter<Plan>();
  inputValid = new EventEmitter<Boolean>();

  constructor(
    private httpService: HttpService,
    private planService: PlanService,
    private utilService: UtilService
  ) {}

  getInputValid() {
    return this.inputValid.asObservable();
  }

  selectPlan(plan: Plan) {
    this.priceId = plan.price.id;
    this.selectedPlan.next(plan);
  }

  getSelectedPlan() {
    return this.selectedPlan.asObservable();
  }

  initializeCardElement(
    { disableDarkMode }: { disableDarkMode: boolean } = {
      disableDarkMode: false,
    }
  ) {
    this.cardElement = this.stripe.elements().create("card", {
      style: {
        base: {
          fontSize: "16px",
        },
      },
    });
    if (disableDarkMode) {
      this.updateCard(false);
    } else {
      this.utilService.getDarkMode().subscribe((dark) => {
        this.updateCard(dark);
      });
    }

    this.cardElement.on("change", (event) => {
      if (event.complete) {
        this.inputValid.next(true);
        this.inputIsValid = true;
      } else if (event.error) {
        this.inputValid.next(false);
        this.inputIsValid = false;
        // this.notificationService.handleError(event.error.message);
      }
    });
  }

  updateCard(dark: boolean) {
    if (dark) {
      this.cardElement.update({
        classes: {
          base: "my-2 p-4 rounded border-2 text-base border-gray-500 hover:border-gray-300",
          focus: "border-gray-100",
        },
        style: {
          base: {
            color: "white",
          },
        },
      });
    } else {
      this.cardElement.update({
        classes: {
          base: "my-2 p-4 rounded border-2 text-base border-gray-500 hover:border-gray-700",
          focus: " border-gray-900",
        },
        style: {
          base: {
            color: "black",
          },
        },
      });
    }
  }

  removePaymentMethod(paymentMethodId: string) {
    return this.httpService.post(`/billing/payment/remove`, {
      payment_method: {
        id: paymentMethodId,
      },
    });
  }

  setDefaultPaymentMethod(paymentMethod) {
    return this.httpService.post("/billing/payment/default", {
      payment_method: paymentMethod,
    });
  }

  getDefaultPaymentMethod() {
    return this.httpService.get("/billing/payment/default");
  }

  getPlansWithPricing(percentageOff: number = 0) {
    return this.getPrices().pipe(
      switchMap(({ prices }) => {
        return this.planService.getPlans(percentageOff).pipe(
          map((_plans) => {
            const plans = _plans.map((plan) => {
              const price = prices.find(
                (price) => price.lookup_key == plan.lookup_key
              );
              return {
                ...plan,
                price,
              };
            });
            return plans;
          })
        );
      })
    );
  }

  getCurrentPlan(): Observable<{ subscription; plan }> {
    return this.getSubscription().pipe(
      switchMap((subscription) => {
        console.log({ subscription });
        return this.getPlansWithPricing().pipe(
          map((plans: Plan[]) => {
            console.log({ plans });
            const plan = plans.find(
              (plan) => plan.price.id === subscription.plan.id
            );
            return {
              plan,
              subscription,
            };
          })
        );
      })
    );
  }

  async confirmCardSetup() {
    const { client_secret } = await this.createSetupIntent();

    if (!client_secret || !this.cardElement) {
      throw "setup key and card element required";
    }

    //save card
    const { setupIntent, error } = await this.stripe.confirmCardSetup(
      client_secret,
      {
        payment_method: {
          card: this.cardElement,
          // billing_details: {
          //   name: "Jenny Rosen",
          // },
        },
      }
    );

    return { setupIntent, error };
  }

  async confirmPayment(
    billing_details: stripe.BillingDetails,
    { payment_key }
  ) {
    const { paymentIntent, error } = await this.stripe.confirmCardPayment(
      payment_key,
      {
        payment_method: {
          card: this.cardElement,
          billing_details,
        },
      }
    );

    if (error) {
      console.error(error);
      throw error;
    }
  }

  // GET
  createSetupIntent() {
    return this.httpService.get("/billing/setup").toPromise();
  }
  getPaymentMethods() {
    return this.httpService.get("/billing/cards");
  }

  getInvoices() {
    return this.httpService.get("/billing/invoices");
  }

  getSubscription() {
    return this.httpService.get("/billing/subscription");
  }

  getPrices() {
    return this.httpService.get("/billing/prices");
  }

  createSubscription(priceId?: string) {
    return this.httpService.post("/billing/subscriptions", {
      priceId: priceId || this.priceId,
      promoId: this.promoId,
    });
  }

  // DELETE

  cancelSubscription(subscriptionId: string) {
    return this.httpService.delete(`/billing/subscriptions/${subscriptionId}`);
  }

  updateSubscription(price_id: string) {
    return this.httpService.put("/billing/subscriptions", {
      price_id,
    });
  }

  // NOT YET REVISED

  createCustomer() {
    return this.httpService.get("/billing/create");
  }

  createCheckoutSession(priceId?: string) {
    return this.httpService.post("/billing/checkout", {
      priceId: priceId || this.priceId,
    });
  }

  fetchPromoCode(promoCode: string) {
    return this.httpService.get(`/billing/promos/${promoCode}`);
  }
}
