import {
  Component,
  Input,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "secondary-button",
  templateUrl: "./secondary-button.component.html",
  styleUrls: ["./secondary-button.component.scss"],
})
export class SecondaryButtonComponent implements AfterViewInit {
  @Input() loading = false;
  @Input() disabled = false;
  @Input() id: string;
  @ViewChild("iconSlot") iconSlot;
  @Output() onClick = new EventEmitter<any>();
  hasIcon: boolean;

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.hasIcon =
      this.iconSlot.nativeElement &&
      this.iconSlot.nativeElement.children?.length > 0;
    this.ref.detectChanges();
  }

  handleClick() {
    this.onClick.emit();
  }
}
